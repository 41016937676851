import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/config';
import plasticSurgeryBreakpoint from './plasticSurgeryBreakpoint';
import { Main } from '../Main';

const PlasticSurgeryContainer = styled.section`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    padding: 2rem 1rem;

    @media (min-width: ${breakpoints.tablet}px) {
        padding: 2rem;
    }

    @media (min-width: ${breakpoints.desktop + 64}px) {
        max-width: ${breakpoints.desktop}px;
        padding: 2rem 0;
        margin: 0 auto;
    }
`;

export const PlasticSurgeryArticleContainer = styled(Main)`
    flex: 1 1 auto;
    @media (min-width: ${plasticSurgeryBreakpoint}px) {
        border-left: 1px solid ${colors.grey40};
        padding-left: 2rem;
        margin-left: 2rem;
    }
`;

export default PlasticSurgeryContainer;
