import React from 'react';
import { DatoCmsImage } from '../../models/DatoCms';
import { DOMModal } from '../modal/DOMModal';
import Backdrop from '../Backdrop';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Dialog from '../Dialog';
import { breakpoints, colors } from '../../styles/config';
import { useI18n } from '../../i18n/useI18n';
import { useKeydown } from '../util/useKeydown';
import { IconButton } from '../Button';

const ModalContainer = styled(Dialog)`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
`;

const ImageContainer = styled.div`
    display: block;
    width: 100%;
    max-width: ${breakpoints.desktop}px;
    padding: 3rem;
`;

const ControlButton = styled(IconButton)`
    position: absolute;
    width: 40px;
    height: calc(100% - 8px);
    font-size: 1.5rem;
    bottom: 4px;
    color: ${colors.white};

    &.is-prev {
        left: 4px;
    }

    &.is-next {
        height: calc(100% - 56px);
        right: 4px;
    }
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 4px;
    right: 4px;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    color: ${colors.white};
`;

export interface ModalImageProps {
    image: DatoCmsImage;
    onClose: () => void;
    prev: () => void;
    next: () => void;
    canNext?: boolean;
    canPrev?: boolean;
}

export default function ModalImage({ image, prev, next, canNext, canPrev, onClose }: ModalImageProps) {
    const { t } = useI18n();

    useKeydown(
        e => {
            switch (e.key) {
                case 'Escape':
                    onClose();
                    break;
                case 'ArrowRight':
                case 'ArrowDown':
                    if (canNext) next();
                    break;
                case 'ArrowLeft':
                case 'ArrowUp':
                    if (canPrev) prev();
                    break;
            }
        },
        null,
        [prev, next, canNext, canPrev]
    );

    if (!image) return null;
    return (
        <DOMModal>
            <ModalContainer>
                <Backdrop show />
                <ImageContainer>
                    <Img
                        {...image}
                        style={{ width: '100%', height: '100%' }}
                        imgStyle={{ objectPosition: 'center', objectFit: 'contain' }}
                    />

                    {canPrev && (
                        <ControlButton className="is-prev" onClick={prev} aria-label={t('gallery.previous')}>
                            <FontAwesomeIcon aria-hidden={true} fixedWidth icon={faChevronLeft} />
                        </ControlButton>
                    )}
                    {canNext && (
                        <ControlButton className="is-next" onClick={next} aria-label={t('gallery.next')}>
                            <FontAwesomeIcon aria-hidden={true} fixedWidth icon={faChevronRight} />
                        </ControlButton>
                    )}
                    <CloseButton aria-label={t('gallery.close')} onClick={onClose}>
                        <FontAwesomeIcon aria-hidden={true} fixedWidth icon={faTimes} />
                    </CloseButton>
                </ImageContainer>
            </ModalContainer>
        </DOMModal>
    );
}

ModalImage.defaultProps = {
    canNext: true,
    canPrev: true,
};
