import React from 'react';
import Title from '../typography/Title';
import FullWidthGallery, { FullWidthGalleryImage } from '../gallery/FullWidthGallery';
import PlasticSurgeryPageElementContainer from './PlasticSurgeryPageElementContainer';

interface PlasticSurgeryBeforeAfterGalleryProps {
    title: string;
    images: FullWidthGalleryImage[];
}

export default function PlasticSurgeryBeforeAfterGallery({ images, title }: PlasticSurgeryBeforeAfterGalleryProps) {
    return (
        <PlasticSurgeryPageElementContainer>
            <Title level={2}>{title}</Title>
            <FullWidthGallery images={images} />
        </PlasticSurgeryPageElementContainer>
    );
}
