import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../../styles/config';
import { useI18n } from '../../i18n/useI18n';
import { constructPath } from '../../i18n/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import plasticSurgeryBreakpoint from './plasticSurgeryBreakpoint';

const BackButtonLink = styled(Link)`
    display: block;
    margin-bottom: 1rem;
    color: ${colors.grey60};
    text-decoration: none;

    @media (min-width: ${plasticSurgeryBreakpoint}px) {
        display: none;
    }

    span {
        margin-left: 0.5rem;
    }

    svg {
        font-size: 14px;
    }
`;

const PlasticSurgeryBackButton: React.FC = ({ children }) => {
    const { language } = useI18n();
    const path = constructPath('plastic-surgery', language);

    return (
        <BackButtonLink to={path}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{children}</span>
        </BackButtonLink>
    );
};

export default PlasticSurgeryBackButton;
