import React, { HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useI18n } from '../../i18n/useI18n';
import styled from 'styled-components';
import { colors } from '../../styles/config';

const GalleryImageOverlayButton = styled.button`
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    color: ${colors.white};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        font-size: 32px;
        margin-bottom: 0.5rem;
    }

    &:hover,
    &:focus {
        opacity: 1;
    }
`;

export interface GalleryImageOverlayProps extends HTMLAttributes<HTMLButtonElement> {
    alt?: string | null;
}

export default function GalleryImageOverlay({ alt, ...props }: GalleryImageOverlayProps) {
    const { t } = useI18n();

    return (
        <GalleryImageOverlayButton {...props} aria-label={t('gallery.fullscreen') + ' ' + (alt || '')}>
            <FontAwesomeIcon aria-hidden={true} icon={faEye} fixedWidth />
            <span aria-hidden={true}>{t('gallery.preview')}</span>
        </GalleryImageOverlayButton>
    );
}
