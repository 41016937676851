import React from 'react';
import Title from '../typography/Title';
import Text from '../typography/Text';
import PlasticSurgeryPageElementContainer from './PlasticSurgeryPageElementContainer';

export interface PlasticSurgeryArticleProps {
    title: string;
    article: string;
}

export default function PlasticSurgeryArticle({ title, article }: PlasticSurgeryArticleProps) {
    return (
        <PlasticSurgeryPageElementContainer>
            <Title level={1}>{title}</Title>
            <Text dangerouslySetSanitizedHTML={article} />
        </PlasticSurgeryPageElementContainer>
    );
}
