import React from 'react';
import { IImportantInformation } from './IImportantInformation';
import Title from '../typography/Title';
import { useI18n } from '../../i18n/useI18n';
import PlasticSurgeryPageElementContainer from './PlasticSurgeryPageElementContainer';
import Table from '../Table';

export interface PlasticSurgeryImportantInformationProps {
    information: IImportantInformation[];
}

export function PlasticSurgeryImportantInformation({
    information,
}: PlasticSurgeryImportantInformationProps): JSX.Element | null {
    const { t } = useI18n();

    if (information.length === 0) return null;

    return (
        <PlasticSurgeryPageElementContainer>
            <Title level={2}>{t('plastic-surgery.important-information')}</Title>
            <Table>
                <tbody>
                    {information.map(({ informationType, informationText }, index) => (
                        <tr key={informationType + informationText + index}>
                            <th>{informationType}</th>
                            <td>{informationText}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </PlasticSurgeryPageElementContainer>
    );
}
