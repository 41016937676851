import React from 'react';
import { graphql } from 'gatsby';
import { I18nContextType } from '../i18n/context';
import { RemarkNode } from '../models/RemarkNode';
import PlasticSurgeryContainer, {
    PlasticSurgeryArticleContainer,
} from '../components/plastic-surgery/PlasticSurgeryContainer';
import PlasticSurgeryMenu from '../components/plastic-surgery/PlasticSurgeryMenu';
import PlasticSurgeryArticle from '../components/plastic-surgery/PlasticSurgeryArticle';
import PlasticSurgeryBackButton from '../components/plastic-surgery/PlasticSurgeryBackButton';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { IImportantInformation } from '../components/plastic-surgery/IImportantInformation';
import { PlasticSurgeryImportantInformation } from '../components/plastic-surgery/PlasticSurgeryImportantInformation';
import { FullWidthGalleryImage } from '../components/gallery/FullWidthGallery';
import PlasticSurgeryBeforeAfterGallery from '../components/plastic-surgery/PlasticSurgeryBeforeAfterGallery';
import { useI18n } from '../i18n/useI18n';
import { PlasticSurgeryMenuContext } from '../components/plastic-surgery/PlasticSurgeryMenuContext';

export interface PlasticSurgeryProps {
    pageContext: {
        i18n: I18nContextType;
        plasticSurgeryMenu: PlasticSurgeryMenuContext;
    };
    data: {
        datoCmsPlasticSurgeryPage: {
            title: string;
            subtitle: string;
        };
        datoCmsPlasticSurgery: {
            title: string;
            textNode: RemarkNode;
            importantInformation: IImportantInformation[];
            gallery: FullWidthGalleryImage[];
            seoMetaTags: any;
        };
    };
}

const PlasticSurgeryArticlePage: React.FC<PlasticSurgeryProps> = ({ data, pageContext }) => {
    const { t } = useI18n();
    return (
        <PlasticSurgeryContainer>
            <HelmetDatoCms seo={data.datoCmsPlasticSurgery.seoMetaTags} />
            <PlasticSurgeryMenu
                title={data.datoCmsPlasticSurgeryPage.title}
                subtitle={data.datoCmsPlasticSurgeryPage.subtitle}
                plasticSurgeryMenu={pageContext.plasticSurgeryMenu}
            />
            <PlasticSurgeryArticleContainer>
                <PlasticSurgeryBackButton>{data.datoCmsPlasticSurgeryPage.title}</PlasticSurgeryBackButton>
                <PlasticSurgeryArticle
                    title={data.datoCmsPlasticSurgery.title}
                    article={data.datoCmsPlasticSurgery.textNode.childMarkdownRemark.html}
                />
                <PlasticSurgeryImportantInformation information={data.datoCmsPlasticSurgery.importantInformation} />
                {data.datoCmsPlasticSurgery.gallery.length > 0 && (
                    <PlasticSurgeryBeforeAfterGallery
                        title={t('plastic-surgery.before-after-gallery-title')}
                        images={data.datoCmsPlasticSurgery.gallery}
                    />
                )}
            </PlasticSurgeryArticleContainer>
        </PlasticSurgeryContainer>
    );
};

export const query = graphql`
    query PlasticSurgeryArticlePage($language: String!, $slug: String!) {
        datoCmsPlasticSurgeryPage(locale: { eq: $language }) {
            title
            subtitle
        }
        datoCmsPlasticSurgery(slug: { eq: $slug }, locale: { eq: $language }) {
            title
            textNode {
                childMarkdownRemark {
                    html
                }
            }
            importantInformation {
                ... on DatoCmsImportantInformation {
                    informationType
                    informationText
                }
            }
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            gallery {
                ...FullWidthGalleryImage
            }
        }
    }
`;

export default PlasticSurgeryArticlePage;
