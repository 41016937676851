import React, { useCallback, useMemo, useState } from 'react';
import Img, { FluidObject } from 'gatsby-image';
import styled from 'styled-components';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from '../../i18n/useI18n';
import { animated, useSpring } from 'react-spring';
import ModalImage from './ImageModal';
import { useKeydown } from '../util/useKeydown';
import GalleryImageOverlay from './GalleryImageOverlay';
import { graphql } from 'gatsby';

// STYLES --------------------------------------------------------------

const GalleryContainer = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.66%;
`;

const ImageContainer = styled(animated.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
`;

const Actions = styled.div`
    position: relative;
`;

const ClearButton = styled.button.attrs({
    type: 'button',
})`
    background: transparent;
    border: none;
    padding: 0.1rem 0.25rem;
    margin: 0.5rem;
    cursor: pointer;
    font-size: 2rem;

    &[disabled] {
        opacity: 0.8;
        cursor: not-allowed;
    }
`;

// ---------------------------------------------------------------------

export interface FullWidthGalleryImage {
    width: number;
    height: number;
    originalId: string;
    alt: string;
    fluid: FluidObject | FluidObject[];
}

export interface FullWidthGalleryProps {
    images: FullWidthGalleryImage[];
}

const GALLERY_ASPECT_RATIO = 100 / 56.66;

export default function FullWidthGallery({ images }: FullWidthGalleryProps) {
    const { t } = useI18n();
    const [current, setCurrent] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const prev = useCallback(() => setCurrent(v => (images.length + v - 1) % images.length), [images]);
    const next = useCallback(() => setCurrent(v => (images.length + v + 1) % images.length), [images]);
    const open = useCallback(() => setOpen(true), []);
    const close = useCallback(() => setOpen(false), []);

    const aspectRatio = images[current].width / images[current].height;

    const reversedImages = useMemo<Array<[FullWidthGalleryImage, number]>>(() => {
        return images.map((image, index) => [image, index] as [FullWidthGalleryImage, number]).reverse();
    }, [images]);

    if (images.length === 0) return null;
    return (
        <>
            <GalleryContainer>
                {reversedImages.map(([image, index]) => (
                    <DisplayImage key={image.originalId} image={image} current={current} index={index} />
                ))}
                <GalleryImageOverlay
                    style={{
                        width: `calc(100% / ${
                            aspectRatio < GALLERY_ASPECT_RATIO ? GALLERY_ASPECT_RATIO / aspectRatio : 1
                        }`,
                        height: `calc(100% / ${
                            aspectRatio > GALLERY_ASPECT_RATIO ? Math.pow(GALLERY_ASPECT_RATIO / aspectRatio, -1) : 1
                        })`,
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}
                    onClick={open}
                />
            </GalleryContainer>
            <Actions>
                <ClearButton aria-label={t('gallery.previous')} onClick={prev} disabled={current === 0}>
                    <FontAwesomeIcon icon={faLongArrowAltLeft} fixedWidth />
                </ClearButton>
                <ClearButton aria-label={t('gallery.next')} onClick={next} disabled={current === images.length - 1}>
                    <FontAwesomeIcon icon={faLongArrowAltRight} fixedWidth />
                </ClearButton>
            </Actions>
            {isOpen && (
                <ModalImage
                    image={images[current]}
                    prev={prev}
                    next={next}
                    canPrev={current > 0}
                    canNext={current < images.length - 1}
                    onClose={close}
                />
            )}
        </>
    );
}

interface DisplayImageProps {
    current: number;
    index: number;
    image: FullWidthGalleryImage;
}

function DisplayImage({ current, index, image }: DisplayImageProps) {
    const trans = Math.sign(index - current) * 30;
    const props = useSpring({
        opacity: current === index ? 1 : 0,
        transformOrigin: `center left`,
        transform: `translate3d(${trans}px, 0, 0)`,
    });

    return (
        <ImageContainer style={props} key={image.originalId}>
            <Img
                style={{ width: '100%', height: '100%' }}
                imgStyle={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    objectPosition: 'center left',
                }}
                fluid={image.fluid}
            />
        </ImageContainer>
    );
}

export const FullWidthGalleryImageFragment = graphql`
    fragment FullWidthGalleryImage on DatoCmsFileField {
        originalId
        alt
        width
        height
        fluid(maxWidth: 1366) {
            ...GatsbyDatoCmsFluid
        }
    }
`;
