import React from 'react';
import styled, { css } from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { breakpoints, colors } from '../../styles/config';
import Title from '../typography/Title';
import plasticSurgeryBreakpoint from './plasticSurgeryBreakpoint';
import { Main } from '../Main';
import {
    PlasticSurgery,
    PlasticSurgeryMenuContext,
    PlasticSurgeryMenuElement,
    PlasticSurgeryMenuGroup,
} from './PlasticSurgeryMenuContext';
import { constructPath } from '../../i18n/util';
import { useI18n } from '../../i18n/useI18n';

// STYLES ---------------------------------------------------------------------

const Container = styled.div<{ mobileHidden: boolean }>`
    display: block;
    flex: none;
    width: 300px;
    max-width: 100%;

    ${p =>
        p.mobileHidden &&
        css`
            display: none;
            @media (min-width: ${plasticSurgeryBreakpoint}px) {
                display: block;
            }
        `};
`;

const StyledTitle = styled(Title)`
    margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
    font-size: 12px;
    line-height: 1.4em;
    color: ${colors.grey60};
    margin-bottom: 1.5rem;
`;

const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const StyledGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    &:not(:first-child) {
        border-top: 1px solid ${colors.grey13};
        margin-top: 1rem;
        padding-top: 0.5rem;
    }
    strong {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1rem;
    }
`;

const StyledLink = styled(Link)`
    margin-bottom: 0.8rem;

    & {
        color: ${colors.grey80};
        text-decoration: none;
    }

    &:hover,
    &:focus,
    &.is-active {
        color: ${colors.blue};
        text-decoration: none;
    }
`;

// ----------------------------------------------------------------------------

function PlasticSurgeryMenuLinksGroup({ group }: { group: PlasticSurgeryMenuGroup }) {
    return (
        <StyledGroup>
            <strong>{group.title}</strong>
            {group.plasticSurgeries.map(l => (
                <PlasticSurgeryMenuLink key={l.slug} link={l} />
            ))}
        </StyledGroup>
    );
}

function PlasticSurgeryMenuLink({ link }: { link: PlasticSurgery }) {
    const { language } = useI18n();
    const path = constructPath('plastic-surgery', language) + '/' + link.slug;
    return (
        <StyledLink to={path} activeClassName="is-active" partiallyActive={true}>
            {link.title}
        </StyledLink>
    );
}

export interface PlasticSurgeryMenuProps {
    isOnMenuPage?: boolean;
    plasticSurgeryMenu: PlasticSurgeryMenuContext;
    title: string;
    subtitle: string;
}

export default function PlasticSurgeryMenu({
    isOnMenuPage,
    plasticSurgeryMenu,
    title,
    subtitle,
}: PlasticSurgeryMenuProps): JSX.Element {
    return (
        <Container as={isOnMenuPage ? Main : 'div'} mobileHidden={!isOnMenuPage}>
            <StyledTitle level={isOnMenuPage ? 1 : 2}>{title}</StyledTitle>
            <Subtitle>{subtitle}</Subtitle>
            <LinksContainer>
                {plasticSurgeryMenu.map(ps => {
                    return isPlasticSurgery(ps) ? (
                        <StyledGroup>
                            <PlasticSurgeryMenuLink key={ps.slug} link={ps} />
                        </StyledGroup>
                    ) : (
                        <PlasticSurgeryMenuLinksGroup key={ps.title} group={ps} />
                    );
                })}
            </LinksContainer>
        </Container>
    );
}

function isPlasticSurgery(ps: PlasticSurgeryMenuElement | PlasticSurgeryMenuGroup): ps is PlasticSurgeryMenuElement {
    return ps.model.apiKey === 'plastic_surgery';
}
